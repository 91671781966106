import React from 'react'
import './footer.css'
import { FaSquareFacebook } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { Link } from "react-scroll";


const Footer = () => {
  return (
    <section id='footer' style={{backgroundImage: `url(${process.env.PUBLIC_URL}/img/footer.jpg)`}}>
        <div className='footer-container'>
            <div className='footer-contents'>
                <div className='footer-logo'>
                    <img className="footlogo" src="./Weqaya-logo.png" alt="WEQAYA LOGO" />
                </div>
                <div className='foot-titles'>
                <p className='footer-content-title'>4903 Aspin Commercial Tower</p>
                <p className='footer-content-title foot-second-title'>Sheikh Zayed Road · PO Box 33233 · Dubai · UAE</p>
                <p className='footer-content-title'>+971 42222500</p>
                <p className='footer-content-title'>info@weqayahealth.com</p>
                </div>
                <div className='footer icons'>
                    <ul className='social-link-container'>
                        <li>
                            <a
                            href="https://www.facebook.com"
                            className="footer-social-link-items"
                            target="_blank"
                            rel="noreferrer"
                            >
                            <FaSquareFacebook size={25} />
                            </a>
                        </li>
                        <li>
                            <a
                            href="https://www.linkedin.com/company/magenta-investments-llc/"
                            className="footer-social-link-items"
                            target="_blank"
                            rel="noreferrer"
                            >
                            <FaLinkedin size={25} />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='footer-links-container'>
                <div className='footer-links'>
                    <ul className='footer-link-container'>
                        <li className='footer-link-items'>
                        <Link
                            activeClass="navbar--active-content"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                            to="mainSection"
                            // className="navbar--content"
                            >
                            HOME
                        </Link>
                        </li>
                        <li className='footer-link-items'>
                            <Link
                                activeClass="navbar--active-content"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                                to="about"
                                // className="navbar--content"
                                >
                                ABOUT
                            </Link>
                        </li>
                        <li className='footer-link-items'>
                            <Link
                            activeClass="navbar--active-content"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                            to="companies"
                            // className="navbar--content"
                            >
                                COMPANIES
                            </Link>
                        </li>
                        <li className='footer-link-items'>
                            <Link
                                activeClass="navbar--active-content"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                                to="news"
                                // className="navbar--content"
                                >
                                NEWS
                            </Link>
                        </li>
                        <li className='footer-link-items'>
                            <Link
                                activeClass="navbar--active-content"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                                to="contact"
                                // className="navbar--content"
                                >
                                CONTACT US
                            </Link>
                        </li>
                        <li className='footer-link-items'>
                        <Link
                            activeClass="navbar--active-content"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                            to="partners"
                            // className="navbar--content"
                            >
                            PARTNERS
                        </Link>
                        </li>
                        <li className='footer-link-items'>
                            <Link
                                activeClass="navbar--active-content"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                                to="career"
                                // className="navbar--content"
                                >
                                CAREER
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <p className='footer-para'>© {new Date().getFullYear()} All Rights Reserved By WEQAYA</p>
    </section>
  )
}

export default Footer